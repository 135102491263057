<template>
	<div class="monitoring-risk-audit">
		<div class="content-box" style="margin-top: 0;">
			<div class="title-div"><b>企业名称: <span>{{companyName}}</span></b></div>
		</div>
		<div class="content-box">
			<div class="title-div">推荐风险点</div>
			<div class="table-box">
				<el-table border empty-text="加载中..." :data="tableDataRiskPointTJ" :height="390" :row-style="{height:'40px'}" style="width: 100%;">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="tagName" label="风险标签" width="200"></el-table-column>
					<el-table-column prop="abstracts" label="风险信息">
						<template slot-scope="scope">
							<el-tooltip placement="top-start" :visible-arrow="false" offset="0" effect="dark">
								<div slot="content">
									<span v-html="scope.row.abstracts">{{scope.row.abstracts}}</span>
								</div>
								<span class="ellipsis" v-html="scope.row.abstracts">{{scope.row.abstracts}}</span>
							</el-tooltip>
						</template>
		 		  </el-table-column>
					<el-table-column prop="url" label="风险来源" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<a class="color-m" :href="scope.row.url" target="_blank">{{scope.row.url}}</a>
						</template>
					</el-table-column>
					<el-table-column prop="insertTime" label="风险点发现时间" width="150" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="status" label="风险判定" width="100"></el-table-column>
					<el-table-column prop="reason" label="备注"></el-table-column>
					<el-table-column prop="" label="操作" width="70">
						<template slot-scope="scope">
							<i class="iconfont icon-bianji color-m" title="编辑" @click="handleEditTJXG(scope.$index, scope.row)" style="cursor: pointer;margin: 0 5px;"></i>
							<i class="iconfont icon-ic_detail color-m" title="详情" @click="handleEditBJTJXQ(scope.$index, scope.row)" style="cursor: pointer;margin: 0 5px;"></i>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
			</div>
		</div>
		<div class="content-box">
			<div class="title-div">风险点 <b class="iconfont icon-bianji2" title="风险点修改" @click="riskPointModal"></b></div>
			<div class="table-box">
				<el-table border :data="tableDataRiskPoint" :height="395" :row-style="{height:'40px'}" style="width: 100%;">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="tagName" label="风险标签" width="200"></el-table-column>
					<el-table-column prop="desc" label="风险信息" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="url" label="风险来源" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.url == 'XF'">{{scope.row.url}}</span>
							<a v-else class="color-m" :href="scope.row.url" target="_blank">{{scope.row.url}}</a>
						</template>
					</el-table-column>
					<el-table-column prop="insertByName" label="审核人" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="insertTime" label="审核时间" width="150" :show-overflow-tooltip="true"></el-table-column>
					<template slot="empty">
						<img class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
			</div>
		</div>
		<!-- 推荐风险点修改   弹框 -->
		<el-dialog title="推荐风险点审核" :visible.sync="dialogVisibleTJXG" width="30%">
			<el-form :inline="true" class="demo-form-inline" :model="ruleFormTJ" ref="ruleFormTJ">
				<el-form-item>
					<el-radio v-model="ruleFormTJ.yesOrNo" label="1" @change='reasonClearable'>正确</el-radio>
					<el-radio v-model="ruleFormTJ.yesOrNo" label="-1">错误</el-radio>
				</el-form-item>
				<el-form-item>
					<el-select v-model="ruleFormTJ.reason" :disabled="ruleFormTJ.yesOrNo == 1" clearable>
						<el-option v-for="(item,index) in ruleFormTJ.reasonList" :key="index" :label="item.reason" :value="item.reason"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveRiskPointTJ('ruleFormTJ')">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 推荐风险点详情   弹框 -->
		<el-dialog title="推荐风险点详情" :visible.sync="outerVisibleTJXQ" width="80%">
			<div class="table-box">
				<el-table border :data="tableRiskPointTJXQ" :row-style="{height:'40px'}" @row-click="handleRowRiskPointTJXQ">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="title" label="名称" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="type" label="类型" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.type == 4">新闻</span>
							<span v-if="scope.row.type == 5">博客</span>
							<span v-if="scope.row.type == 6">论坛</span>
							<span v-if="scope.row.type == 8">微信</span>
							<span v-if="scope.row.type == 9">微博</span>
							<span v-if="scope.row.type == 10">APP</span>
						</template>
					</el-table-column>
					<el-table-column prop="pubtime" label="时间" width="200"></el-table-column>
					<el-table-column prop="sourceUrl" label="来源" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<a class="color-m" :href="scope.row.sourceUrl" target="_blank">{{scope.row.sourceUrl}}</a>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalRiskPointTJXQ" :current-page="pageNumRiskPointTJXQ" :page-size="pageSizeRiskPointTJXQ" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
			<!-- 内层  单条详情 -->
			<el-dialog width="60%" title="" :visible.sync="innerVisibleTJXQ" append-to-body class="modal-box">
				<div class="modal-body-box" v-loading="loadingFlagTJXQ">
					<div class="ellipsis modal-title" :title="titleTJXQ" v-html="titleTJXQ">{{titleTJXQ}}</div>
					<div class="modal-content">
						<span v-if="sourceTypeTJXQ == 4">信息源：<b>新闻</b></span>
						<span v-if="sourceTypeTJXQ == 9">信息源：<b>微博</b></span>
						<span v-if="sourceTypeTJXQ == 10">信息源：<b>APP</b></span>
						<span v-if="sourceTypeTJXQ == 5">信息源：<b>网站</b></span>
						<span v-if="sourceTypeTJXQ == 8">信息源：<b>微信</b></span>
						<span v-if="sourceTypeTJXQ == 6">信息源：<b>论坛</b></span>
						<span v-if="pubtimeTJXQ != ''">发布时间：<b>{{pubtimeTJXQ}}</b></span>
					</div>
					<div class="modal-article">
						<div style="width: 100%;height: 600px;">
							<el-scrollbar style="height: 100%;width:100%">
								<span v-html="contentTJXQ">{{contentTJXQ}}</span>
							</el-scrollbar>
						</div>
					</div>
				</div>
			</el-dialog>
		</el-dialog>
		<!-- 风险点修改   弹框 -->
		<el-dialog title="风险点修改" :visible.sync="dialogVisible" :show-close="false" width="50%">
			<span class="iconfont icon-jia3 color-m" style="position: absolute;top: 20px;right: 50px;font-size: 20px;" @click="addRiskPointItem"></span>
			<div style="width: 100%;height: 572px;">
				<el-scrollbar style="height: 100%;width:100%">
					<el-form :inline="true" class="demo-form-inline" :model="ruleFormSG" ref="ruleFormSG">
						<div v-for="(item, index) in ruleFormSG.ruleItem" :key="index">
							<el-form-item label="风险点" prop="tag_name">
								<el-select v-model="item.tag_name">
									<el-option v-for="(iem, ind) in ruleFormSG.riskPointSelect" :key="ind" :label="iem.tagName" :value="`${iem.id}&${iem.tagName}`"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="风险来源" prop="url">
								<el-input v-model="item.url" placeholder="请输入风险来源"></el-input>
							</el-form-item>
							<el-form-item label="风险信息" prop="abstract">
								<el-input v-model="item.abstract" placeholder="请输入风险信息"></el-input>
							</el-form-item>
							<el-form-item>
								<span class="iconfont icon-jian3 color-m" style="font-size: 20px" @click="deleteRiskPointItem(item, index)"></span>
							</el-form-item>
						</div>
					</el-form>
					<div v-if="ruleFormSG.ruleItem.length == 0">
						<img :src="`${baseUrl}img/bt-nodata-now.png`" />
					</div>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveRiskPoint('ruleFormSG')">确 定</el-button>
				<el-button @click="dialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "monitoring-risk-audit",
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			companyName: this.$route.query.name,
			dataTableEmpty: false,
			tableDataRiskPointTJ: [], //列表  推荐风险点
			dialogVisibleTJXG: false, //是否打开编辑弹框  推荐风险点修改
			ruleFormTJ: {
				yesOrNo: "",
				reason: '',
				riskTagsId: ""
			},
			outerVisibleTJXQ: false, //是否打开编辑弹框  推荐风险点详情  外层
			outerVisibleTJXQtagId:'',
			outerVisibleTJXQcompanyId:'',
			tableRiskPointTJXQ: [], //列表  推荐风险点详情  外层
			totalRiskPointTJXQ: 0,
			pageNumRiskPointTJXQ: 1,
			pageSizeRiskPointTJXQ: 10,
			innerVisibleTJXQ: false, //是否打开编辑弹框  推荐风险点详情  内层
			loadingFlagTJXQ: false,
			titleTJXQ: "",
			sourceTypeTJXQ: "",
			pubtimeTJXQ: "",
			contentTJXQ: "",
			tableDataRiskPoint: [], //列表  风险点
			dialogVisible: false, //是否打开编辑弹框  风险点修改
			ruleFormSG: {
				riskPointSelect: [],
				ruleItem: []
			},
		}
	},
	created() {
		this.getRiskPointTJ(); //加载列表   推荐风险点
		this.getRiskPoint(); //加载列表   风险点
		this.getRiskpointReasonList(); //加载判断弹框   错误原因
	},
	mounted() {},
	methods: {
		//加载列表   推荐风险点
		getRiskPointTJ() {
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/riskPoint/getRecommendRiskPointList', {
				params: {
					companyId: this.$route.query.id,
				}
			}).then(res => {
				this.dataTableEmpty = true;
				this.tableDataRiskPointTJ = res.data;
			})
		},
		//推荐风险点修改  弹框
		handleEditTJXG(index, result) {
			this.dialogVisibleTJXG = true; //打开弹框  
			if (result.status == "正确") {
				this.ruleFormTJ.yesOrNo = "1";
				this.ruleFormTJ.reason = "";
			} else if (result.status == "错误") {
				this.ruleFormTJ.yesOrNo = "-1";
				this.ruleFormTJ.reason = result.reason;
			} else {
				this.ruleFormTJ.yesOrNo = "";
				this.ruleFormTJ.reason = "";
			}
			this.ruleFormTJ.riskTagsId = result.id;
		},
		//加载判断弹框   错误原因
		getRiskpointReasonList() {
			this.$provider.get('/wg-ifrRisk/riskPoint/getRiskpointReasonList', {}).then(res => {
				this.ruleFormTJ.reasonList = res.data;
			})
		},
		//正确    错误原因   清空
		reasonClearable() {
			this.ruleFormTJ.reason = "";
		},
		//推荐风险点修改   确定保存
		saveRiskPointTJ(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.ruleFormTJ.yesOrNo != "") {
						this.$provider.post('/wg-ifrRisk/riskPoint/riskAudit', {
							"id": Number(this.ruleFormTJ.riskTagsId),
							"status": this.ruleFormTJ.yesOrNo,
							"reason": this.ruleFormTJ.reason,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: "推荐风险点判断成功",
									type: 'success'
								});
								this.dialogVisibleTJXG = false; //关闭弹框
								this.getRiskPointTJ(); //加载列表 推荐风险点
								this.getRiskPoint(); //加载列表   风险点
							} else {
								this.$message({
									message: res.msg,
									type: 'error'
								});
								return false;
							}
						})
					} else {
						alert("请先选择正确与错误");
						return false;
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		//推荐风险点详情  弹框
		handleEditBJTJXQ(index, result) {
			this.outerVisibleTJXQ = true; //打开弹框  外层
			this.outerVisibleTJXQtagId = result.tagId;
			this.outerVisibleTJXQcompanyId = result.companyId;
			this.tableDataRiskPointTJXQ(); //推荐风险点详情  弹框   列表
		},
		//推荐风险点详情  弹框   列表
		tableDataRiskPointTJXQ() {
			this.$provider.get('/wg-ifrRisk/riskPoint/getRiskDetailList', {
				params: {
					tagId: this.outerVisibleTJXQtagId,
					companyId: this.outerVisibleTJXQcompanyId,
					current: this.pageNumRiskPointTJXQ,
					size: this.pageSizeRiskPointTJXQ,
				}
			}).then(res => {
				this.tableRiskPointTJXQ = res.data.records;
				this.totalRiskPointTJXQ = res.data.total;
			})
		},
		//点击 推荐风险点详情列表  属于第几页
		handleCurrentChange(val) {
			this.pageNumRiskPointTJXQ = val;
			this.tableDataRiskPointTJXQ(); //加载  推荐风险点详情  弹框   列表
		},
		//点击 推荐风险点详情列表的某一行    加载对应详情
		handleRowRiskPointTJXQ(row, event, column) {
			this.outerVisibleTJXQ = true; //打开弹框  外层
			this.innerVisibleTJXQ = true; //打开弹框  内层
			this.getDetailInfoTJXQ(row.id);
		},
		getDetailInfoTJXQ(result) {
			this.loadingFlagTJXQ = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/riskPoint/getDetailInfo', {
				params: {
					riskDetailId: result
				}
			}).then(res => {
				this.loadingFlagTJXQ = false; //loading 消失
				this.titleTJXQ = res.data.title;
				this.sourceTypeTJXQ = res.data.source_type;
				this.pubtimeTJXQ = res.data.pubtime;
				this.contentTJXQ = res.data.content;
			})
		},

		//加载列表   风险点
		getRiskPoint() {
			this.$provider.get('/wg-ifrRisk/riskPoint/getRiskPointList', {
				params: {
					companyId: this.$route.query.id,
				}
			}).then(res => {
				this.tableDataRiskPoint = res.data;
			})
		},
		//风险点修改  弹框
		riskPointModal() {
			this.dialogVisible = true; //打开弹框
			this.getRiskPointSelect(); //风险点修改   加载风险点  Select
			//风险点修改   加载  回显
			this.ruleFormSG.ruleItem = []; //初始化 数据
			this.tableDataRiskPoint.filter((item, index) => {
				this.ruleFormSG.ruleItem.push({
					"tag_name": item.tagId + "&" + item.tagName,
					"url": item.url,
					"abstract": item.desc
				})
			})
		},
		//风险点修改   新增   添加行
		addRiskPointItem() {
			this.ruleFormSG.ruleItem.push({
				tag_name: "3&消费返利",
				url: '',
				abstract: "",
			})
		},
		//风险点修改   删除   删除行
		deleteRiskPointItem(item, index) {
			this.ruleFormSG.ruleItem.splice(index, 1)
		},
		//风险点修改   加载风险点  Select
		getRiskPointSelect() {
			this.$provider.get('/wg-ifrRisk/riskPoint/getRiskTagList', {}).then(res => {
				this.ruleFormSG.riskPointSelect = res.data;
			})
		},
		//风险点修改   确定保存
		saveRiskPoint(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let result = [];
					this.ruleFormSG.ruleItem.filter((item, index) => {
						result.push({
							"companyId": this.$route.query.id,
							"tagId": item.tag_name.split("&")[0],
							"tagName": item.tag_name.split("&")[1],
							"url": item.url,
							"desc": item.abstract,
							"humanFlag": 1,
							"status": 0,
						})
					})
					this.$provider.post('/wg-ifrRisk/riskPoint/addRiskPoints', {
						companyId: this.$route.query.id,
						companyRiskPoints: result
					}).then(res => {
						this.$message({
							message: "风险点修改成功",
							type: 'success'
						});
						this.dialogVisible = false; //关闭弹框
						this.getRiskPoint(); //加载列表   风险点
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		//去掉所有的html标记    并保留文本
		delHtmlTag(str) {
			return str.replace(/<[^>]+>/g, ""); //去掉所有的html标记
		}
	},
}
</script>

<style lang="stylus" scoped="scoped"></style>
